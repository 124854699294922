import { Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingIndicator from "../../../app/components/loading-indicator";
export default function DonwloadPaymentControls() {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.downloadPayment)
  return (
    <>
      <LoadingIndicator open={loading}/>
      <Paper sx={{padding: 1, marginBottom: 1}}>
        
      </Paper>  
    </>
  )
}