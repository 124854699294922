import { CustomerPaymentFormI } from "../../features/customers/customer-view/customer-view.state";
import Api from "../axios"
import { CustomerPaymentInterface } from "../models/payment.interface";
import { FileUtils } from "../../utils/file.utils";
import { FeePaymentsResultI } from "../models/fee-payment-result-inteface";
import { PaymentResumeI } from "../../features/user-customers/user-customers.state";
import { OfficePaymentsResume } from "../models/office-dashboard-payment-row";
import { FilterPaymentsType } from "../models/filter-payments.type";
import { PaymentForDownloadType } from "../models/payment-for-download.type";


const api = new Api()

export async function addPaymentReq({form, customerId} : {form: CustomerPaymentFormI, customerId: string}): Promise<CustomerPaymentInterface> {
  try {
    const data = {
      customer: customerId,
      valueExpected: Number(form.value),
      valuePayed: 0,
      dateExpected: form.date,
      debtCollector: form.debtCollector,
    }
    const addPayment = await api.post({  path: `payments`, data,})
    console.log({ addPayment })
    const { error } = addPayment
    if (error == null) {
      return addPayment.result
    } else {
      throw new Error(addPayment)
    }
  } catch (error) {
    throw error
  }
}

export async function customerPaymentsReq({customerId} : {customerId : string}): Promise<CustomerPaymentInterface[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `payments/${customerId}`})
    //console.log('customerPayments', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON customerPayments');
    console.error({error});
    throw error;
  }
}

export async function addFeePaymentReq({ customerId, image, paymentRequestId, value } : { customerId : string, paymentRequestId: string, image: any[], value: number}): Promise<CustomerPaymentInterface>  {
  try {
    const api = Api.getInstance()
    const filesFormat = await image.map((file: any) => FileUtils.dataUrlToFile(file.src, file.name))
    const filesFormatted: Blob[] = await Promise.all(filesFormat)
  
    const formData = new FormData()
    for(const f of filesFormatted) {
      formData.append("image", f)
    }
    formData.append("value", String(value))
    formData.append("paymentRequest", paymentRequestId)
    formData.append("customer", customerId)
    
    const response = await api.post({path: `payments/fee-payment`, data: formData, isFormData: true})
    console.log('addFeePaymentReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON addFeePaymentReq');
    console.error({error});
    throw error;
  }
}

export async function getUnstrustedPaymentsReq(): Promise<FeePaymentsResultI[]>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `payments/untrusted-payments`})
    console.log('getUnstrustedPaymentsReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getUnstrustedPaymentsReq');
    console.error({error});
    throw error;
  }
}

export async function confirmImageFeePaymentReq({feePaymentId} : {feePaymentId: string}): Promise<boolean>  {
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `payments/confirm-image-payment/${feePaymentId}`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON confirmImageFeePayment');
    console.error({error});
    throw error;
  }
}

export async function getUserPaymentsByDatesReq({userId, endDate, startDate} : {userId : string, endDate: string, startDate: string}): Promise<PaymentResumeI>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `payments/get-payments-by-user/${userId}`, data: {endDate, startDate}})
    //console.log('getUserPaymentsByDates', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getUserPaymentsByDates');
    console.error({error});
    throw error;
  }
}

export async function getOfficePaymentsByDatesReq({officeId, dateStart, dateEnd} : {officeId : string, dateStart: string, dateEnd: string}): Promise<OfficePaymentsResume>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `payments/get-payments-by-office/${officeId}`, data: {endDate: dateEnd, startDate: dateStart}})
    //console.log('getOfficePaymentsByDatesReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getUserPaymentsByDates');
    console.error({error});
    throw error;
  }
}

export async function filterMadePaymentsReq(filter : FilterPaymentsType): Promise<PaymentForDownloadType[]>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `payments/filter-fee-payments`, data: filter})
    console.log('filterPaymentReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON filterPaymentReq');
    console.error({error});
    throw error;
  }
}