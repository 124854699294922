import { Button, Grid, Paper, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppTextField from "../../../app/components/app-textfield";
import { Search } from "@mui/icons-material";
import { showDialogFindPaymentAct } from "../business-logic/download-payment.slice";
export default function DownloadPaymentForm() {
  const dispatch = useAppDispatch()
  const {pickedPayment} = useAppSelector((state) => state.downloadPayment) 

  const showDialogFindPayment = () => dispatch(showDialogFindPaymentAct(true))
  return (
    <Paper sx={{padding: 1, marginBottom: 1}}>
      <Grid container>
        <Grid item xs={12}>
          <AppTextField 
            label="Pago" readonly
            value={pickedPayment !== undefined ? `[C:${pickedPayment.customer.name}] [$:${pickedPayment.value}] [U:${pickedPayment.paymentRequest.user.lastName} ${pickedPayment.paymentRequest.user.email}]` : ""}
            startCompontent={<Typography>Pago:</Typography>}
            endComponent={<Button size="small" color="secondary" onClick={showDialogFindPayment} variant="outlined"> <Search/> </Button>}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}