import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DownloadPaymentState, FilterPaymentsForm } from "./download-payment.state";
import { getCurrenDateUtil } from "../../../utils/date.utils";
import { filterMadePaymentsReq } from "../../../app/services/payments.service";
import { PaymentForDownloadType } from "../../../app/models/payment-for-download.type";
const filterPaymentFormInit: FilterPaymentsForm = {
  collector: "",
  dateEnd: getCurrenDateUtil(),
  dateInit: getCurrenDateUtil(),
  office: "",
  userId: "",
}
const initialState: DownloadPaymentState = {
  loading: false,
  foundPayments: [],
  showFindPaymentDialog: false,
  filterPaymentForm: filterPaymentFormInit,
}
export const findPaymentsThunk = createAsyncThunk( "DownloadPayment/findPaymentsThunk", async (filter: FilterPaymentsForm) => await filterMadePaymentsReq(filter))

export const downloadPaymentSlice = createSlice({
  name: "downloadPayment",
  initialState,
  reducers: {
    showDialogFindPaymentAct: (state, action: PayloadAction<boolean>) => {
      state.showFindPaymentDialog = action.payload
    },
    updateInputFilterPaymentAct: (state, action: PayloadAction<{key: string, value: string}>) => {
      state.filterPaymentForm[action.payload.key] = action.payload.value
    },
    pickPaymentForDownloadAct: (state, action: PayloadAction<PaymentForDownloadType>) => {
      state.pickedPayment = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(findPaymentsThunk.fulfilled, (state, action) => {
      state.foundPayments = action.payload
      state.pickedPayment = undefined
    })
    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("DownloadPayment"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("DownloadPayment"), (state) => {
      state.loading = false
    })
  },
})
export const { showDialogFindPaymentAct, updateInputFilterPaymentAct, pickPaymentForDownloadAct } = downloadPaymentSlice.actions
export default downloadPaymentSlice.reducer