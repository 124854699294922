import { Grid, Paper, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { MonetizationOn, Money, Search } from "@mui/icons-material";
import AppTextField from "../../../app/components/app-textfield";
export default function DownloadPaymentRoute() {
  const dispatch = useAppDispatch()
  const { pickedPayment } = useAppSelector((state) => state.downloadPayment) 
  return (
    <Paper sx={{padding: 1, marginBottom: 1}}>
      {pickedPayment === undefined && <Typography variant="h5">Selecciona un pago para descargar. <Search/></Typography>}
      {pickedPayment !== undefined && <>
        <Grid container>
          <Grid item xs={3}>
            <AppTextField startCompontent={<MonetizationOn/>} label="Valor" readonly value={pickedPayment.value}/>
          </Grid>
          <Grid item xs={3}>
            <AppTextField startCompontent={<MonetizationOn/>} label="Valor" readonly value={pickedPayment.value}/>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </>}
    </Paper>
  )
}